<style src="./code-search-mobile.component.scss" lang="scss"> </style>

<template>
  <div class="code-search-mobile d-lg-none d-block">
    <accordion-component>
      <v-card class="mb-10" v-for="(item, index) in streetByIndex" :key="index">
        <accordion-item-component>
          <template v-slot:title>
            <div class="information">
              <div class="information__title">
                {{ locale.nameStreet }}
              </div>
              <div class="information__description">
                {{ item.streetName }}
              </div>
            </div>
            <div class="information">
              <div class="information__title">
                {{ locale.postalCode }}
              </div>
              <div class="information__description">
                {{ item.postIndex }}
              </div>
            </div>
            <div class="information">
              <div class="information__title">
                {{ locale.city }}
              </div>
              <div class="information__description">
                {{ item.cityName }}
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div class="information mt-20">
              <div class="information__title">
                {{ locale.numberHouse }}
              </div>
              <div class="information__description">
                {{item.address }}
              </div>
            </div>
            <div class="information">
              <div class="information__title">
                {{ locale.codeStreet }}
              </div>
              <div class="information__description">
                {{item.streetCode }}
              </div>
            </div>
            <div class="information">
              <div class="information__title">
                {{ locale.area }}
              </div>
              <div class="information__description">
                {{ item.regionName }}
              </div>
            </div>
          </template>
        </accordion-item-component>
      </v-card>
    </accordion-component>
  </div>
</template>

<script src="./code-search-mobile.component.ts"></script>