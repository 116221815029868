<style src="./code-search.component.scss" lang="scss"></style>

<template>
  <div class="code-search">
    <div class="row">
      <div class="col-lg-6 col-12">
        <form class="form" @submit.prevent="sendData">
          <label for="code" class="v-label">{{ locale.subTitleHeaderZipCode }}</label>
          <div class="form__group d-lg-flex text-center ">
            <input
                v-model="code"
                @input="checkCode"
                :placeholder="locale.placeholderZipCode"
                type="text" class="v-input mr-20" id="code">
            <v-divider class="d-lg-none d-block mt-30 mb-30"/>
            <v-btn color="dark">{{ locale.search }}</v-btn>
          </div>
        </form>
      </div>
      <div class="col-12 d-lg-block d-none" v-if="streetByIndex.length">
        <table-component class="mt-40" id="postIndexTable">
          <thead>
          <tr>
            <th>{{ locale.nameStreet }}</th>
            <th class="text-center">{{ locale.numberHouse }}</th>
            <th class="text-center">{{ locale.codeStreet }}</th>
            <th class="text-center"> {{ locale.area }}</th>
            <th class="text-center">{{ locale.city }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in streetByIndex" :key="index">
            <th>{{ item.streetName }}</th>
            <th class="text-center">{{ item.address }}</th>
            <th class="text-center">{{ item.streetCode }}</th>
            <th class="text-center">{{ item.regionName }}</th>
            <th class="text-center">{{ item.cityName }}</th>
          </tr>
          </tbody>
        </table-component>
      </div>
    </div>
  </div>
</template>

<script src="./code-search.component.ts"></script>
