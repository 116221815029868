<style src="./street-search.component.scss" lang="scss"></style>

<template>
  <div class="street-search">
    <div class="row">
      <div class="col-lg-6 col-12">
        <form class="form " @submit.prevent="sendData">
          <div class="form__group ">
            <div class="row">
              <div class="col-lg-9 col-12">
                <label class="v-label">{{ locale.subTitleHeaderStreet }}</label>
                <input
                    @input="error = false"
                    :class="{'error--input':error}"
                    v-model="street"
                    :placeholder="locale.placeholderStreet"
                    type="text" class="v-input mr-20" id="codeSearch">
              </div>
              <div class=" col-12">
                <div class="row">
                  <div class="col-12">
                    <label class="v-label mt-20">{{ locale.citySearch }}</label>
                  </div>
                  <div class="col-lg-9">
                    <v-select
                        @select="selectCity"
                        :placeholder="locale.city"
                        :options="streetCity"/>
                  </div>
                  <div class="col-12 d-lg-none d-block ">
                    <v-divider class="mt-30 mb-30"/>

                  </div>
                  <div class="col-lg-3 col-12 text-center">
                    <v-btn
                        color="dark">{{ locale.search }}
                    </v-btn>
                  </div>
                </div>

                <div class="form__group d-lg-flex ">



                </div>
              </div>
              <div class="col-lg-3 col-12">

              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="col-12 d-lg-block d-none" v-if="streetByName.length">
        <table-component class="mt-40" id="streetTable">
          <thead>
          <tr>
            <th>{{ locale.nameStreet }}</th>
            <th class="text-center">{{ locale.numberHouse }}</th>
            <th class="text-center">{{ locale.codeStreet }}</th>
            <th class="text-center"> {{ locale.postalCode }}</th>
            <th class="text-center">{{ locale.city }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in streetByName" :key="index">
            <th>{{ item.streetName }}</th>
            <th class="text-center">{{ item.address }}</th>
            <th class="text-center">{{ item.streetCode }}</th>
            <th class="text-center">{{ item.postIndex }}</th>
            <th class="text-center">{{ item.cityName }}</th>
          </tr>
          </tbody>
        </table-component>
      </div>
    </div>
  </div>
</template>

<script src="./street-search.component.ts"></script>
