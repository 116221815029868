<style src="./tab-radio.component.scss" lang="scss"></style>

<template>
  <div class="tab-tadio">
    <div class="tab-radio__input-wrapper ">
      <div class="tab-radio__radio">
        <input
            @input="changeViewTab(1)"
            v-model="viewTab"
            type="radio"
            class="v-radio v-radio--circle v-radio--dark-blue"
            value="1" id="1">
        <label for="1" class="v-label">{{ nameLeftInput }}</label>
      </div>
      <div class="tab-radio__radio">
        <input
            @input="changeViewTab(2)"
            v-model="viewTab"
            type="radio"
            class="v-radio v-radio--circle v-radio--dark-blue"
            value="2" id="2">
        <label for="2" class="v-label">{{ nameRightInput }}</label>
      </div>
    </div>
  </div>
</template>

<script src="./tab-radio.component.ts"></script>