import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class StreetSearchServiceSandbox {
    private store = store;

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getStreetByPostIndex(payload: any): any {
        return this.store.dispatch('getStreetByPostIndex', payload);
    }

    getStreetCity(): any {
        return this.store.dispatch('getStreetCity');
    }

    getStreetByName(payload: any): any {
        return this.store.dispatch('getStreetByName', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }
}