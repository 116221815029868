import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'tab-radio-component',
    props: {
        nameLeftInput: {
            type: String,
            require:true
        },
        nameRightInput: {
            type: String,
            require:true
        }
    }
})

export default class TabRadioComponent extends Vue {
    public viewTab = 1;

    public changeViewTab(id: number) {
        this.$emit('changeViewTab', id);
    }
}