import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {StreetSearchServiceSandbox} from '@/pages/street-search-service/sandbox/street-search-service.sandbox';
import {IServiceCommon} from '@/types/service-common';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import CodeSearchComponent from '@/pages/street-search-service/components/code-search/code-search.component.vue';
import StreetSearchComponent from '@/pages/street-search-service/components/street-search/street-search.component.vue';
import {IServiceHeader} from '@/types/service-header';
import TabRadioComponent from '@/core/shared/tab-radio/tab-radio.component.vue';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import StreetSearchMobileComponent
    from '@/pages/street-search-service/components/street-search-mobile/street-search-mobile.component.vue';
import CodeSearchMobileComponent
    from '@/pages/street-search-service/components/code-search-mobile/code-search-mobile.component.vue';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'street-search-service-component',
    components: {
        ServiceNameComponent,
        ServiceSubtitleComponent,
        CodeSearchComponent,
        StreetSearchComponent,
        TabRadioComponent,
        StreetSearchMobileComponent,
        CodeSearchMobileComponent
    }
})

export default class StreetSearchServiceComponent extends mixins(Router) {
    public cityCode = '';
    public service = 33;
    private sandbox = container.resolve(StreetSearchServiceSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public viewMobile = 1;
    public pageNumberStreet = 1;
    public pageSizeStreet = 10;
    public pageNumberPostIndex = 1;
    public pageSizePostIndex = 10;
    public street = '';
    public postIndex = '';
    public $refs!: {
        paginationStreet: {
            reset: () => void;
        },
        paginationPostIndex: {
            reset: () => void;
        },
    };

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get cities(): ISearchSelect[] {
        return this.store.getters.getCities;
    }

    get totalCountStreetByIndex(): number {
        return this.store.getters._getTotalCountStreetByPostIndex;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.streetSearch) {
                this.locale = res.streetSearch;
            }
        });
    }
    public mounted() {
        this.getServiceData();
    }

    get totalCountStreetByName(): number {
        return this.store.getters._getTotalCountStreetByName;
    }

    public changeViewTab(info: number) {
        this.viewMobile = info;
    }

    public selectCity(info: any) {
        this.cityCode = info;
    }


    public changePageStreet(info: any) {
        this.pageNumberStreet = info.pageNumber;
        this.pageSizeStreet = info.pageSize;
        let data = {
            cityCode: this.cityCode,
            streetName: this.street,
            pageNumber: this.pageNumberStreet,
            pageSize: this.pageSizeStreet,
        };
        this.sandbox.getStreetByName(data);
        this.scrollTable('streetTable', 'streetTableMobile');
    }

    public changePagePostIndex(info: any) {
        this.pageNumberPostIndex = info.pageNumber;
        this.pageSizePostIndex = info.pageSize;
        let data = {
            postIndex: this.postIndex,
            pageNumber: this.pageNumberPostIndex,
            pageSize: this.pageSizePostIndex,
        };
        this.sandbox.getStreetByPostIndex(data);
        this.scrollTable('postIndexTable', 'postIndexTableMobile');

    }

    public changeStreet(info: string) {
        this.store.commit('SET_STREET_BY_NAME_RESET');
        this.street = info;
        let data = {
            cityCode: this.cityCode,
            streetName: this.street,
            pageNumber: this.pageNumberStreet,
            pageSize: this.pageSizeStreet,
        };
        this.sandbox.getStreetByName(data);
        this.resetPaginationStreet();
        this.scrollTable('streetTable', 'streetTableMobile');

    }

    public changePostIndex(info: string) {
        this.store.commit('SET_STREET_BY_POST_INDEX_RESET');
        this.postIndex = info;
        let data = {
            postIndex: info,
            pageNumber: this.pageNumberPostIndex,
            pageSize: this.pageSizePostIndex,
        };
        this.sandbox.getStreetByPostIndex(data);
        this.resetPaginationPostIndex();
        this.scrollTable('postIndexTable', 'postIndexTableMobile');
    }

    public resetPaginationStreet() {
        if (this.pageNumberStreet > 1) {
            this.$refs.paginationStreet.reset();
            this.pageNumberStreet = 1;
        }
    }

    public resetPaginationPostIndex() {
        if (this.pageNumberPostIndex > 1) {
            this.$refs.paginationPostIndex.reset();
            this.pageNumberPostIndex = 1;
        }
    }

    public scrollTable(idTable: string, idMobile: string) {
        const table = document.querySelector<HTMLElement>(`#${idTable}`)?.offsetTop;
        const tableMobile = document.querySelector<HTMLElement>(`#${idMobile}`)?.offsetTop;
        if (table) {
            window.scroll(0, table);
        } else if (tableMobile) {
            window.scroll(0, tableMobile);
        }
    }

    public unmounted() {
        this.store.commit('SET_STREET_BY_NAME_RESET');
        this.store.commit('SET_STREET_BY_POST_INDEX_RESET');
    }


}
