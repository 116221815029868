import 'reflect-metadata';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {Options, Vue} from 'vue-class-component';
import AccordionComponent from '@/core/shared/accordion/accordion.component.vue';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';
import {IStreet} from '@/pages/street-search-service/types/street';

@Options({
    name: 'code-search-mobile-component',
    components: {
        AccordionComponent,
        AccordionItemComponent,
    }
})

export default class CodeSearchMobileComponent extends Vue {
    public locale$: any = locale;
    private store = store;
    public locale: any = null;

    get streetByIndex(): IStreet[] {
        return this.store.getters._getStreetByPostIndex;
    }

    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.streetSearch) {
                this.locale = res.streetSearch;
            }
        });
    }
}