import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import TableComponent from '@/core/shared/table/table.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {StreetSearchServiceSandbox} from '@/pages/street-search-service/sandbox/street-search-service.sandbox';
import {IStreet} from '@/pages/street-search-service/types/street';

@Options({
    name: 'code-search-component',
    components: {
        TableComponent,
        NotDataComponent
    }
})

export default class CodeSearchComponent extends Vue {
    private sandbox = container.resolve(StreetSearchServiceSandbox);
    public locale$: any = locale;
    private store = store;
    public locale: any = null;
    public code: string = '';
    public pageNumber = 1;
    public pageSize = 10;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    get streetByIndex(): IStreet[] {
        return this.store.getters._getStreetByPostIndex;
    }



    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.streetSearch) {
                this.locale = res.streetSearch;
            }
        });
    }

    public sendData() {
        if (this.code?.trim()) {
            this.$emit('changePostIndex', this.code);
        }
    }

    public checkCode() {
        this.code = this.code.replace(/[^0-9]+/g, '').slice(0, 4);
    }

}
