import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TableComponent from '@/core/shared/table/table.component.vue';
import {container} from 'tsyringe';
import {StreetSearchServiceSandbox} from '@/pages/street-search-service/sandbox/street-search-service.sandbox';
import {IStreet} from '@/pages/street-search-service/types/street';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

@Options({
    name: 'street-search-component',
    components: {
        TableComponent,
        NotDataComponent
    }
})

export default class StreetSearchComponent extends Vue {
    private sandbox = container.resolve(StreetSearchServiceSandbox);
    public locale$: any = locale;
    private store = store;
    public locale: any = null;
    public street: string = '';
    public pageNumber = 1;
    public pageSize = 10;
    public error = false;

    get streetByName(): IStreet[] {
        return this.store.getters._getStreetByName;
    }

    get streetCity(): ISearchSelect[] {
        return this.store.getters._getStreetCity;
    }


    get totalCountStreetByName(): number {
        return this.store.getters._getTotalCountStreetByName;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.streetSearch) {
                this.locale = res.streetSearch;
            }
        });
        this.sandbox.getStreetCity();
    }

    public sendData() {
        this.validation();
        if (!this.error) {
            this.$emit('changeStreet', this.street);
        }
    }

    public validation() {
        if (this.street.trim().length > 0) {
            this.error = false;
        } else {
            this.error = true;
        }
    }

    public selectCity(info: any) {
        this.$emit('selectCity', info);
    }

}
