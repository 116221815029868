<style src="./street-search-service.component.scss" lang="scss"></style>

<template>
  <div class="street-search-service mb-100">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section class="mt-20 ">
            <v-card class="h-container">
              <!--              <services-subtitle-component>-->
              <!--                {{ locale.header }}-->
              <!--              </services-subtitle-component>-->
              <tab-radio-component
                  @changeViewTab="changeViewTab"
                  :nameRightInput="locale.titleHeaderZipCode"
                  :nameLeftInput="locale.titleHeaderStreet"/>
              <street-search-component
                  @selectCity="selectCity"
                  @changeStreet="changeStreet"
                  class="mt-40"
                  v-if="viewMobile === 1"/>
              <code-search-component
                  @changePostIndex="changePostIndex"
                  class="mt-40"
                  v-if="viewMobile === 2"/>
            </v-card>
            <street-search-mobile-component id="streetTableMobile" class="mt-20" v-if="viewMobile === 1"/>
            <code-search-mobile-component id="postIndexTableMobile" class="mt-20" v-if="viewMobile === 2"/>
            <v-pagination
                v-if="viewMobile === 1"
                ref="paginationStreet"
                :limit="pageSizeStreet"
                :dataCount="totalCountStreetByName"
                :page="pageNumberStreet"
                @changePage="changePageStreet"
                class="mt-40 mb-100  "/>
            <v-pagination
                v-if="viewMobile === 2"
                ref="paginationPostIndex"
                :limit="pageSizePostIndex"
                :dataCount="totalCountStreetByIndex"
                :page="pageNumberPostIndex"
                @changePage="changePagePostIndex"
                class="mt-40 mb-100  "/>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./street-search-service.component.ts"></script>
